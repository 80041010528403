import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'inverse', 'fieldset', 'remove']
  static values = {
    option: String
  }

  connect() {
    if (this.hasCheckboxTarget) {
      this.toggle()
    }
  }

  toggle() {
    this.fieldsetTargets.forEach((fieldset) => { fieldset.classList.add('hide') } )
    if (this.hasInverseTarget) { this.inverseTarget.classList.remove('hide') }

    this.checkboxTargets.forEach((el) => {
      if (el.checked && el.value === this.optionValue) {
        this.fieldsetTargets.forEach((fieldset) => { fieldset.classList.remove('hide') } )
        if (this.hasRemoveTarget) { this.removeTarget.remove() }
      } else if (this.hasInverseTarget) {
        this.inverseTarget.classList.add('hide')
      }
    })
  }
}
